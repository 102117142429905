import { Carousel } from '@mantine/carousel';
import { ActionIcon, Badge, Box, Button, CopyButton, Drawer, DrawerProps, Grid, Group, Image, Stack, Text, Tooltip, rem } from '@mantine/core';
import { IconCheck, IconCircleCheck, IconCircleX, IconCopy, IconPrinter } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    data?: any;
    setSelectedData?: (id: string) => void;
} & DrawerProps;

export function ShowOrderDrawer(props: Props) {
    const { t } = useTranslation();
    const [order, setOrder] = useState<any>(null);

    useEffect(() => {
        if ("data" in props && props.data !== null) {
            setOrder(props?.data)
        }
    }, [props.data])

    const closeModal = () => {
        props.onClose();
    };

    return (
        <Drawer {...props} onClose={closeModal} styles={{body: {background: "#eee"}}}>
            <Grid gutter={15} justify="center" h={"100%"}>
                <Grid.Col span={12} >
                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label02')}</Text>
                        <Text fw={600}>{order?.fullName}</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} >
                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label03')}</Text>
                        <Text fw={600}>{order?.phone}</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} >
                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label04')}</Text>
                        <Text fw={600}>{order?.price} {t("currency")}</Text>
                    </Group>
                </Grid.Col>

                <Grid.Col span={12} >
                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label06')}</Text>
                        <Text fw={600}>{
                            order?.status === "pending" ? <Badge radius={'sm'} color='yellow' variant='light'>{t('drawer.order.badge01')}</Badge> 
                            : order?.status === "confirmed" ? <Badge radius={'sm'} color='green' variant='light'>{t('drawer.order.badge02')}</Badge> 
                                : order?.status === "closed" ? <Badge radius={'sm'} color='red' variant='light'>{t('drawer.order.badge03')}</Badge>
                                : order?.status === "abandoned" ? <Badge radius={'sm'} color='orange' variant='light'>{t('drawer.order.badge04')}</Badge> : null
                        }</Text>
                    </Group>
                </Grid.Col>
                
                <Grid.Col span={12} >
                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label08')}</Text>
                        <Text size='sm' fw={500} c={"gray.8"}>{dayjs(order?.createdAt).format("HH:mm YYYY-MM-DD")}</Text>
                    </Group>
                </Grid.Col>
                
                {/* <Grid.Col span={12} >
                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label10')}</Text>
                        <Text size='sm' fw={500} c={"gray.8"}>{order?.state}</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} >
                    <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                        <Text fw={400} c={"gray.7"}>{t('drawer.order.label11')}</Text>
                        <Text size='sm' fw={500} c={"gray.8"}>{order?.city}</Text>
                    </Group>
                </Grid.Col> */}
            </Grid>
        </Drawer>
    );
}